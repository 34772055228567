// @ts-nocheck
import React from "react";
// utils
import { imageError } from "../../utils/errors";
// images
import downloadImage from "../../asset/image/btn-download.png";

const DownloadLink = ({ forMobile = false, socialLink = null }) => {
  let links = [
    // Play Store
    {
      id: 1,
      image: downloadImage,
      url: socialLink?.play_store || null,
      is_qr_code: false,
    },
  ];

  const handleToggle = () => {
    document.getElementById("qr-code-scan").classList.toggle("active");
  };
  if (forMobile)
    return (
      <div
        className="position-absolute d-none shadow-lg bg-danger rounded-circle d-inline-flex justify-content-center align-items-center"
        style={{
          zIndex: 9999999,
          right: "1rem",
          bottom: "1rem",
          width: 60,
          height: 60,
        }}
      >
        <div className="text-center">
          {links?.map(({ id, image, is_qr_code, url }) => (
            <a
              href={url}
              key={id}
              target="_blank"
              onClick={() => {
                if (is_qr_code && image !== "http://") handleToggle();
              }}
              className="shadow-lg"
              rel="noreferrer"
            >
              <img
                src={image}
                alt="downloadImage"
                style={{ width: "60px", height: "60px", objectFit: "contain" }}
                onError={imageError}
              />
            </a>
          ))}
        </div>
      </div>
    );

  // For Website
  return (
    <div className="fixed-at-side fixed-at-side--l d-md-flex flex-column gap-2 py-2 px-2 text-center text-white">
      {links?.map(({ id, image, is_qr_code, url }) => (
        <a
          href={url}
          key={id}
          target="_blank"
          onClick={() => {
            if (is_qr_code && image !== "http://") handleToggle();
          }}
          className=""
          rel="noreferrer"
        >
          <img
            src={image}
            alt="..."
            style={{ width: "200px", height: "100px", objectFit: "cover" }}
            onError={imageError}
            className="d-block rounded-2"
          />
        </a>
      ))}
    </div>
  );
};

export default DownloadLink;
