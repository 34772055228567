export const VIETNAM = {
    home: "trang chủ",
    mainBalance: "Main Balance",
    gameBalance: "Game Balance",
    profile: "Hồ sơ",
    friend: "Referral",
    betslip: "Bet Slip",
    history: "Lịch sử",
    cashIn: "Tiền mặt",
    cashOut: "Rút tiền",
    logout: "đăng xuất",
    login: "Đăng nhập",
    gameCode: "Game Code",
    turnOver: "Turnover",
    transferPlaceHolder: "Please enter amount you want to transfer.",
    pleaseTransfer: "Please Transfer",
    searchGame: "Tìm kiếm trò chơi...",
    twoD: "2D",
    threeD: "3D",
    winNoHistory: "thắng số",
    moreGame: "More Games",
    playNow: "Play Now",
    transfer: "Transfer",
    apply: "Áp dụng",
    notice: "Notice",
    back: "Mặt sau",
    category: {
      newGame: "Những trò chơi mới",
      football: "Sách thể thao",
      lottery: "Vé số",
      cardGame: "Chơi bài",
      liveCasino: "Live Casino",
      fishingGame: "Đánh bắt cá",
      slotGame: "Chỗ",
      promotion: "Promotion",
      blog: "Blog",
      other: "Other Games",
    },
    profilePage: {
      level1: "Your account is level 1",
      level2: "Your account is level 2",
      upgradeLevel: "Upgrade Level",
      upgradeToLevel2: "Upgrade Level 2",
      level2Image:
        "Please upload a screenshot of Wave Pay, KBZPay, or AYA Pay Level 2",
      changePassword: "Đổi mật khẩu",
      oldPassword: "Mật khẩu cũ",
      newPassword: "mật khẩu mới",
      userName: "Tên tài khoản",
      email: "Email",
      phone: "Số điện thoại",
      dateOfBirth: "Ngày sinh",
      referralCode: "Referral Code",
      yourReferralCode: "Your Refer Code",
      editProfile: "Chỉnh sửa hồ sơ",
      confirm: "Xác nhận",
      send: "gửi",
    },
    referralPage: {
      yourFriend: "Your Friends",
      gameRefAmount: "Game Refer Amount",
      claim: "Claim",
      name: "Name",
      date: "Date",
      section: "Section",
      amount: "Amount",
    },
    betslipPage: {
      twodSlips: "Phiếu cược 2D",
      threedSlips: "Phiếu cược 3D",
      cashinHistory: "Lịch sử rút tiền",
      cashoutHistory: "Lịch sử rút tiền",
      maintoGameBalanceHistory: "Main to Game Balance History",
      gametoMainBalanceHistory: "Game to Main Balance History",
      gameHistory: "Lịch sử trò chơi",
      date: "Ngày",
      threeddate: "3D Ngày mở",
      section: "Phần",
      totalAmount: "Tổng cộng",
      totalBetNumbers: "Tổng số cược",
      winAmount: "Số tiền thắng",
      status: "Trạng thái",
      paymentName: "Tên thanh toán",
      accountHolderName: "Tên Chủ Tài khoản",
      transationID: "TID or Voucher Code",
      oldBalance: "Số dư cũ",
      cashinAmount: "Cashin Amount",
      cashoutAmount: "Cashout Amount",
      newBalance: "Cân bằng mới",
      winloss: "Win/loss",
      commission: "Commission",
      profitloss: "Profit/Loss",
      mainBalance: "Main Balance",
      transferAmount: "Transfer Amount",
      gameBalance: "Game Balance",
      referenceID: "Reference ID",
      validTurnover: "Valid Turnover",
      userId: "User ID",
      gameType: "GameType",
      stake: "Stake",
      totalBet: "Total Bet",
      totalPayout: "Total Payout",
      winLose: "Win/Lose",
      Provider: "Provider",
      BetAmount: "Số tiền đặt cược",
      PayoutAmount: "Số tiền xuất chi",
      wagerID: "Wager",
      beforeBalance: "Trước số dư",
      BALANCE: "Sự cân bằng",
      Game: "Game",
    },
    cashInPage: {
      cashin: "Tiền mặt",
      holdername: "Tên chủ sở hữu",
      transationID: "Số giao dịch",
      lastsixdigits: "last 6 digits",
      accountNumber: "số tài khoản",
      amount: "Số lượng",
      transferToAmount: "Vui lòng chuyển khoản vào tài khoản sau.",
      request: "Lời yêu cầu",
      promoCode: "Promo Code",
      voucherCode: "Mã chứng từ",
    },
    cashOutPage: {
      cashout: "Cash Out",
      holdername: "Tên chủ sở hữu",
      accountNumber: "số tài khoản",
      amount: "Số lượng",
      request: "Lời yêu cầu",
    },
    loginPage: {
      signIn: "Đăng nhập",
      phone: "Số điện thoại",
      password: "Mật khẩu",
      registerAcc: "Register an account",
      forgetPassword: "Quên mật khẩu?",
    },
    registerPage: {
      userName: "Tên tài khoản",
      phone: "Số điện thoại",
      password: "Mật khẩu",
      refferalCode: "Referral code",
      register: "Đăng ký",
      accountAlreadyExist: "Bạn co săn san để tạo một tai khoản?",
      changePhone: "Đổi mật khẩu",
      send: "gửi",
      confirmPassword: "Xác nhận mật khẩu",
    },
    forgetPassPage: {
      phone: "Số điện thoại",
      send: "Send",
      password: "Password",
      confirmPassword: "Xác nhận mật khẩu",
      resetPassTitle: "Nhập số điện thoại di động đã đăng ký của bạn để yêu cầu OTP.",
    },
    sectionTwoD: {
      lottery: "Vé số",
      sportBook: "Sport Book",
      liveCasino: "Live Casino",
    },
    warning: {
      setAmount: "Please set amount first.",
      bettingClosed: "Betting has been closed.",
      lotteryClosed: "No digit data, lottery closed?",
      needLevel2: "Your account need to be level 2.",
      transferMainToGame: "Please at least transfer Main to Game once.",
      cannotClaim: "Cannot claim less than 0 Game Referr Amount.",
    },
    success: {
      cashInReq: "Cashin request sent!",
      cashOutReq: "Cashout request sent!",
      successChangePass: "Your password has been changed successfully!",
      verifyCodeSent: "Mã xác minh đã gửi!",
    },
    winHistoryPage: {
      luckyNo: "Con số may mắn",
      section: "Phần",
      date: "Ngày",
    },
    winnerListHistory: {
      title: "Danh sách người chiến thắng",
      section: "Section",
      lucky_number: "Con số may mắn",
      no: "No.",
      phone: "Số điện thoại",
      bet_amount: "Số tiền đặt cược",
      win_amount: "Số tiền thắng",
    },
  };
  