import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAkkrr-FaeZjjv-8eeQI6p44pcF8zc2SqY",
  authDomain: "sotmel-c6868.firebaseapp.com",
  projectId: "sotmel-c6868",
  storageBucket: "sotmel-c6868.appspot.com",
  messagingSenderId: "55120366837",
  appId: "1:55120366837:web:f18cd387765148eabe89bc",
  measurementId: "G-J9EECP6M70",
};

const app = initializeApp(firebaseConfig, "secondary");
const db = getDatabase(app);
let messaging = null;
try {
  messaging = getMessaging(app);
} catch (error) {
  console.log("Does not support current browser or be use https");
}
const database = getDatabase(app);
const vapidKey =
  "BHzjcdGdUHweUm-ZMu0zZcMtxeDNxNTkIgSXPJwCCOjW4Y4uY4ghM-gKfHxUiRsAfuzrsqLTyiUetk8FKKaWT2o";

export { ref, onValue, database, messaging, onMessage, vapidKey, getToken, db };
