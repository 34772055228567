// @ts-nocheck
import React, { useCallback } from "react";
// Lib
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { formatPhone } from "../../utils/phoneRegex";

//images
import gold from "../../asset/image/medal/gold-medal.png";
import silver from "../../asset/image/medal/silver-medal.png";
import bronze from "../../asset/image/medal/bronze-medal.png";

function PocketWinnerList({ history, setDate, date }) {
  let { t } = useTranslation();

  const top3 = useCallback((value) => {
    if (value === 1) {
      return (
        <img
          src={gold}
          alt="medal"
          className="img-fluid"
          style={{ width: "30px" }}
        />
      );
    } else if (value === 2) {
      return (
        <img
          src={silver}
          alt="medal"
          className="img-fluid"
          style={{ width: "30px" }}
        />
      );
    } else if (value === 3) {
      return (
        <img
          src={bronze}
          alt="medal"
          className="img-fluid"
          style={{ width: "30px" }}
        />
      );
    } else {
      return value;
    }
  }, []);

  return (
    <div className="container">
      <div className="d-flex flex-column justify-content-between gap-2 mt-1">
        {/* History Filter Section */}
        <input
          type="date"
          onKeyDown={(e) => e.preventDefault()}
          value={moment(date.start).format("YYYY-MM-DD")}
          onChange={(e) => setDate({ ...date, start: e.target.value })}
          id="nofocus"
          className="form-control bg-dark text-white form-control-sm rounded-0 border-0"
        />

        <div className="mt-3 overflow-auto text-center">
          <table className="mt-2 table" style={{ minWidth: "500px" }}>
            <thead>
              <tr
                // style={{ backgroundColor: "#471712" }}
                className="text-white text-center bg-primary"
              >
                <th>{t("winnerListHistory.no")}</th>
                <th>{t("winnerListHistory.phone")}</th>
                <th>{t("winnerListHistory.win_amount")}</th>
                <th>{t("winHistoryPage.date")}</th>
              </tr>
            </thead>
            <tbody>
              {history?.length > 0 ? (
                history?.map((hs, idx) => (
                  <tr className="text-white" key={idx}>
                    <td>{top3(idx + 1)}</td>
                    <td>{formatPhone(hs?.user?.phone)}</td>
                    <td className="fw-bold text-white">
                      {hs.promo_amount}
                      <span
                        className="ms-1 text-muted"
                        style={{ fontSize: "6px" }}
                      >
                        MMK
                      </span>
                    </td>
                    <td>{moment(hs.created_at).format("DD-MM-YYYY")}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">
                    <h6 className="text-white small py-3">No Data!</h6>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PocketWinnerList;
