// @ts-nocheck
import React from "react";
//image
import logo from "../../asset/image/favicon.png";
import noimage from "../../asset/image/no-image.png";

import {
  GiftIcon,
  BellIcon,
  BuildingStorefrontIcon,
  CreditCardIcon,
  BanknotesIcon,
  ArrowLeftOnRectangleIcon,
  ListBulletIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { LoginContext } from "../../store/context/LoginProvider";
import { USER_LOGOUT } from "../../store/actions/actionTypes";
import { BaseURL } from "../../api/apiUrl";
import { useEffect } from "react";
import { getProfileApi } from "../../api/profileApi";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const Sidebar = ({ loading }) => {
  const { t } = useTranslation();
  const [image, setImage] = useState("");

  let profile_image = image?.startsWith("https")
    ? image
    : `${BaseURL}/${image}`;
  const [
    {
      userData,
      gameManagement: { category },
    },
    dispatch,
  ] = useContext(LoginContext);
  const navigate = useNavigate();

  useEffect(() => {
    userData &&
      getProfileApi({ token: userData?.token }).then((res) => {
        setImage(res.image);
      });
  }, [userData]);

  const handleLogout = () => {
    if (!window.confirm("Are you sure want to logout ?")) return;
    dispatch({ type: USER_LOGOUT });
    navigate("/");
  };

  return (
    <div
      className="vh-100 position-fixed top-0 d-none d-md-block col-md-3 col-lg-2 bg-black bg-opacity-50 shadow overflow-auto pb-2"
      style={{ zIndex: 110 }}
    >
      <div
        onClick={() => navigate("/")}
        className="rounded-bottom text-center cursor-pointer"
      >
        <LazyLoadImage
          effect="blur"
          className="w-50 img-fluid rounded-circle"
          src={logo}
        />
      </div>

      {/** Profile Div */}
      <div
        className="text-center mx-auto rounded-2 "
        style={{
          width: "90%",
          backgroundColor: "",
          marginTop: "4rem",
        }}
      >
        <div className="d-block">
          {
            userData?.token ? 
            <LazyLoadImage
            effect="blur"
            className=" rounded-circle translate-start-y translate-middle-y border border-3 border-primary"
            style={{ width: "3rem", height: "3rem", objectFit: "cover" }}
            src={userData ? profile_image : noimage}
          /> : ""
          }
        
          <button
            onClick={() => navigate("/profile")}
            className="button-85 px-5"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-6"
            >
              <path
                fillRule="evenodd"
                d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                clipRule="evenodd"
              />
            </svg>

            <b>{userData?.token ? `${t("profile")}` : `${t("login")}`}</b>
          </button>
          <div></div>
        </div>
      </div>

      {/** History */}
      {userData && (
        <div
          onClick={() => navigate("/betslip")}
          style={{ width: "90%" }}
          className="button-85 px-5 mx-auto bg-success bg-opacity-25 d-flex align-items-center gap-3 rounded-3 mt-3 py-2 cursor-pointer"
        >
          <BuildingStorefrontIcon className="text-white ms-4" width={25} />
          <div className=" text-md text-white ">{t("history")}</div>
        </div>
      )}

      {/** WinnerList & Win-Num */}
      {userData && (
        <div
          style={{ width: "90%" }}
          className="mx-auto bg-opacity-25 rounded-3 mt-3 py-2"
        >
          <div
            onClick={() => navigate("/winner-list")}
            className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
          >
            <UserGroupIcon className="text-white ms-4" width={25} />
            <div className=" text-md text-white ">
              {t("winnerListHistory.title")}
            </div>
          </div>

          <div
            onClick={() => navigate("/win-number")}
            className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
          >
            <ListBulletIcon className="text-white ms-4" width={25} />
            <div className=" text-md text-white ">{t("winNoHistory")}</div>
          </div>

          {/** <div
            onClick={() => navigate("/referral")}
            className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
          >
            <img
              src="https://static.thenounproject.com/png/2215862-200.png"
              width="25"
              className="bg-warning bg-opacity-75 rounded-circle p-1 ms-4"
            />
            <div className=" text-md text-white ">
              {t("friend")}
            </div>
          </div> */}
        </div>
      )}

      {/** Transfer */}
      {userData && (
        <div
          style={{ width: "90%" }}
          className="mx-auto bg-opacity-25 rounded-3 mt-3 py-2"
        >
          <div
            onClick={() => navigate("/cashin")}
            className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
          >
            <CreditCardIcon className="text-white ms-4" width={25} />
            <div className=" text-md text-white ">{t("cashIn")}</div>
          </div>

          <div
            onClick={() => navigate("/cashout")}
            className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
          >
            <BanknotesIcon className="text-white ms-4" width={25} />
            <div className=" text-md text-white ">{t("cashOut")}</div>
          </div>
        </div>
      )}

      {/** Category */}
      <div
        style={{ width: "90%" }}
        className="mx-auto bg-opacity-25 rounded-3 mt-3 py-2"
      >
        <div
          onClick={() => navigate("/")}
          className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
        >
          <LazyLoadImage
            effect="blur"
            className="ms-4"
            style={{ width: "1.5rem" }}
            src="https://cdn-icons-png.flaticon.com/512/1299/1299961.png"
          />
          <div className=" text-md text-white pt-1 font-monospace fw-bolder ">
            {t("home")}
          </div>
        </div>

        {!loading ? (
          category?.map((cat, idx) => (
            <div
              key={idx}
              onClick={() => navigate(`${cat.name}`)}
              className="d-flex align-items-center gap-3 py-2 border border-top-0 border-end-0 border-start-0 border-dark cursor-pointer"
            >
              <LazyLoadImage
                effect="blur"
                className="ms-4"
                style={{ width: "1.5rem" }}
                src={`${BaseURL}/${cat.image}`}
              />
              <div className="text-md text-white font-monospace pt-1  fw-bolder">
                {cat.name.toUpperCase()}
              </div>
            </div>
          ))
        ) : (
          <div className="w-100 h-auto">
            {Array.from({ length: 5 }).map((_, idx) => (
              <div key={idx} className="d-flex align-items-center gap-2">
                <div
                  className="pulse activebg-opacity-50 my-2 rounded-circle"
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    marginLeft: "1.4rem",
                  }}
                ></div>
                <div
                  key={idx}
                  className="pulse text-center text-opacity-25 bg-opacity-50 my-2 py-1 rounded-5"
                  style={{ width: "60%", height: "1.3rem" }}
                ></div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/** Logout */}
      {userData && (
        <div
          onClick={handleLogout}
          style={{ width: "90%" }}
          className="mx-auto button-85 px-5 bg-danger bg-opacity-25 d-flex align-items-center gap-3 rounded-3 mt-3 py-2 cursor-pointer"
        >
          <ArrowLeftOnRectangleIcon className="text-danger ms-4" width={25} />
          <div className="  text-md text-white ">{t("logout")}</div>
        </div>
      )}
    </div>
  );
};
