import React from "react";
// lib
import { GridLoader, HashLoader } from "react-spinners";

const Loading = ({ loading = false, full = false }) => {
  return (
    <div
      className={`my-auto d-flex ${
        full && "vh-100"
      } justify-content-center align-items-center`}
    >
      <GridLoader
       color="#053b62"
       loading={loading}
       size={18}
       aria-label="Loading Spinner"
       data-testid="loader"
      />
    </div>
  );
};

export default Loading;
