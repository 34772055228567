// @ts-nocheck
import React, { useCallback, useState } from "react";
// lib
import i18n from "i18next";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// shared-comp
import HeaderMarquee from "../../components/shared_comp/HeaderMarquee";
// images
import HeaderIcon from "../../asset/image/auland.png";
import MMFlag from "../../asset/image/localization/mm.png";
import UKFlag from "../../asset/image/localization/uk.png";
import ChinaFlag from "../../asset/image/localization/china.png";
import ThaiFlag from "../../asset/image/localization/tai.png";
import VeFlag from "../../asset/image/localization/ve.png";
// utils
import { moneyFormatter } from "../../utils/moneyFormatter";
import { removeLocalStorageNoJson } from "../../utils/localStorage";
import pointIcon from "../../asset/image/point-image.png";
// config
import {
  CN,
  CURRENT_LANG,
  EN,
  MM,
  THAI,
  VE,
} from "../../localization/langConfig";
import { useEffect } from "react";
import axios, { Axios } from "axios";
import { BaseURL } from "../../api/apiUrl";
import { claimPocket, claimPromotion } from "../../api/promotionApi";
import { toastError, toastSuccess } from "../../utils/toast";
import { useConfettie } from "../../store/zustand";

const FlagImage = ({ flagImg, width }) => {
  return (
    <img style={{ width, objectFit: "contain" }} src={flagImg} alt="..." />
  );
};

const WebsiteHeader = ({
  userData,
  handleToggle,
  handleLogout,
  isDisabled,
  marquee,
  promoMaintain,
}) => {
  let { t } = useTranslation();
  const getLang = removeLocalStorageNoJson(CURRENT_LANG);
  const [amount, setAmount] = useState(userData?.balance || 0);
  const [language, setchangeLanguage] = useState(getLang ? getLang : MM);
  const [openLang, setOpenLang] = useState(false);
  const [point, setPoint] = useState(userData?.point || 0);
  const [loading, setLoading] = useState(false);
  const [p_balance, setPBalance] = useState(userData?.p_balance || 0);

  const [ploading, setPloading] = useState(false);
  const { onConOpen, setShowVD, setPocketAmount } = useConfettie();

  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    setchangeLanguage(lang);
    setOpenLang(false);
  };

  useEffect(() => {
    setPoint(userData?.point || 0);
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        axios
          .get(`${BaseURL}/api/profile?id=${userData.id}`)
          .then(({ data }) => {
            setAmount(data?.balance);
            setPoint(data?.point);
            setPBalance(data?.p_balance);
          })
          .catch((error) => {
            console.log(error);
          });
        console.log("suu");
      } else {
        // The tab is hidden
      }
    };

    setAmount(userData?.balance || 0);
    setPoint(userData?.point || 0);
    setPBalance(userData?.p_balance || 0);

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [userData?.balance, userData?.id, userData?.p_balance, userData?.point]);

  const handlePromoClaim = useCallback(async () => {
    if (promoMaintain === "1") return;

    setLoading(true);
    try {
      await claimPromotion({ token: userData?.token })
        .then((res) => toastSuccess(res.message))
        .finally(() => setLoading(false));
    } catch (error) {
      const err = await error.json();
      toastError(err.message);
      setLoading(false);
    }
  }, [promoMaintain, userData?.token]);

  const handleGiftBox = useCallback(
    (value) => {
      if (!onConOpen || !setShowVD) return;

      setPocketAmount(value);
      onConOpen();
      setShowVD();
    },
    [onConOpen, setShowVD, setPocketAmount]
  );

  const handlePocketClaim = async () => {
    if (ploading) return;
    setPloading(true);

    await claimPocket({ token: userData?.token })
      .then(async (res) => {
        if (res.status === "success") {
          setPloading(false);

          //fetch user's balance again
          await axios
            .get(`${BaseURL}/api/profile?id=${userData?.id}`)
            .then(({ data }) => {
              setAmount(data?.balance);
              setPoint(data?.point);
              setPBalance(data?.p_balance);
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => setPloading(false));

          handleGiftBox(res.amount);
        }
      })
      .catch(async (err) => {
        setPloading(false);
        const error = await err.json();
        toastError(error.message);
      });
  };

  return (
    <div
      className="d-none position-relative d-lg-flex justify-content-end justify-content-md-between align-items-center py-4"
      style={{ height: "40px" }}
    >
      {/* Lang Switch */}
      <div
        className="d-flex justify-content-end pe-3"
        style={{ paddingLeft: "10px" }}
      >
        <div
          onClick={() => setOpenLang(!openLang)}
          className="d-flex cursor-pointer align-items-center px-2"
        >
          {language === EN ? (
            <FlagImage width={35} flagImg={UKFlag} />
          ) : language === MM ? (
            <FlagImage width={35} flagImg={MMFlag} />
          ) : language === CN ? (
            <FlagImage width={35} flagImg={ChinaFlag} />
          ) : language === VE ? (
            <FlagImage width={35} flagImg={VeFlag} />
          ) : (
            <FlagImage width={35} flagImg={ThaiFlag} />
          )}
          {/* Expand Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#fff"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
          </svg>
        </div>
        {/* Announcement Icon */}
        {/* <img width={30} height={30} src={HeaderIcon} alt="..." /> */}
      </div>
      {/* Land Switch: DropDown  */}
      <div
        className={`position-absolute ${
          openLang ? "d-flex" : "d-none"
        } flex-column gap-1 bg-white rounded-3 p-2`}
        style={{ left: 5, top: 40, zIndex: 9999 }}
      >
        <div
          className={`${
            language !== MM ? "d-flex" : "d-none"
          } gap-2 align-items-center cursor-pointer`}
          onClick={() => changeLang(MM)}
        >
          <FlagImage width={25} flagImg={MMFlag} />
          <span>ဗမာစာ</span>
        </div>
        <div
          className={`${
            language !== EN ? "d-flex" : "d-none"
          } gap-2 align-items-center cursor-pointer`}
          onClick={() => changeLang(EN)}
        >
          <FlagImage width={25} flagImg={UKFlag} />
          <span>English</span>
        </div>
        <div
          className={`${
            language !== THAI ? "d-flex" : "d-none"
          } gap-2 align-items-center cursor-pointer`}
          onClick={() => changeLang(THAI)}
        >
          <FlagImage width={25} flagImg={ThaiFlag} />
          <span>ไทย</span>
        </div>
        <div
          className={`${
            language !== CN ? "d-flex" : "d-none"
          } gap-2 align-items-center cursor-pointer`}
          onClick={() => changeLang(CN)}
        >
          <FlagImage width={25} flagImg={ChinaFlag} />
          <span>中国人</span>
        </div>
        <div
          className={`${
            language !== VE ? "d-flex" : "d-none"
          } gap-2 align-items-center cursor-pointer`}
          onClick={() => changeLang(VE)}
        >
          <FlagImage width={25} flagImg={VeFlag} />
          <span>Vietnam</span>
        </div>
      </div>
      <div className="bs-flex d-flex align-items-center">
        <HeaderMarquee marquee={marquee} />
      </div>
      <div className="d-flex justify-content-end align-items-center">
        {userData?.token ? (
          <div
            className="d-flex flex-row-reverse ps-3 justify-content-between align-items-center gap-3"
            style={{ color: "white" }}
          >
            <div className="bg-primary bg-opacity-75 rounded-2 p-1 text-center d-flex flex-wrap justify-content-center">
              {/* Main Balance */}
              <svg
                className="float-start"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                height={21}
                width={21}
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z" />
              </svg>
              <span className="px-1 fw-bolder fs-6 font-monospace px-md-3 text-white">
                {userData?.balance ? moneyFormatter(parseInt(amount || 0)) : 0}
              </span>
            </div>
                  {/** Point */}
            <Link to="/point" className="point_div ">
              <img
                src={pointIcon}
                style={{ width: "2.3rem" }}
                alt="point"
                className="point_image"
              />
              <small className="  button-64">
                Point - {Math.floor(point)}
              </small>
            </Link>

            {promoMaintain === "0" && (
              <button
                disabled={loading}
                onClick={handlePromoClaim}
                className={`${
                  loading && "btn-secondary "
                } btn button-85 btn-sm`}
              >
                Claim - {Math.floor(p_balance)}
              </button>
            )}

            <button
              disabled={ploading}
              onClick={handlePocketClaim}
              className="btn button-85 btn-sm fw-bold"
            >
              Pocket Money
            </button>
          </div>
        ) : (
          <div className="w-100 d-flex gap-3 ps-3">
            <Link
              to="/login"
              className="text-decoration-none d-flex align-items-center justify-content-end"
            >
              <button className="rounded-5 btn btn-primary px-5">
                <span className="fw-bolder">{t("login")}</span>
              </button>
            </Link>
            <Link
              to="/register"
              className="text-decoration-none d-flex align-items-center justify-content-end"
            >
              <button className="rounded-5 btn btn-white bg-white px-5">
                <span className="fw-bolder">{t("registerPage.register")}</span>
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default WebsiteHeader;
