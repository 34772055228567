import i18n from "i18next";
// lib
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// locales
import { ENGLISH } from "./locales/en";
import { CHINESE } from "./locales/cn";
import { MYANMAR } from "./locales/mm";
import { THAI } from "./locales/thai";
import { VIETNAM } from "./locales/ve";
// config
import { CURRENT_LANG, EN, MM,VE } from "./langConfig";

const getLang = localStorage.getItem(CURRENT_LANG);

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: {
            en: { // english
                translation: ENGLISH
            },
            thai: { // thai
                translation: THAI
            },
            cn: { // chinese
                translation: CHINESE
            },
            mm: { // myanmar
                translation: MYANMAR
            },
            ve: { // myanmar
                translation: VIETNAM
            }
        },
        react: {
            useSuspense: false,
        },
        lng: getLang ? getLang : MM,
        fallbackLng: EN,
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });
export default i18n;
